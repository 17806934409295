<template>
  <b-card
    v-if="invoice.id"
    class="advanced-invoice"
  >
    <h4 class="card-title mb-0">
      Edição
    </h4>
    <h6 class="mb-1">
      Fatura Nº {{ pad(invoice.invoice_number, 6) }}
    </h6>
    <b-form
      @submit.prevent
    >
      <b-row>
        <b-col
          cols="12"
          md="12"
          xl="6"
        >
          <div>
            <label for="institution">Instituição</label>
            <b-form-input
              id="institution"
              :value="invoice.institution.name"
              name="institution"
              disabled
            />
          </div>
          <b-form-group
            label="Nota"
            label-for="note"
          >
            <b-form-textarea
              id="note"
              v-model="invoice.note"
              rows="4"
              placeholder="Nota..."
            />
          </b-form-group>

        </b-col>
        <b-col
          cols="12"
          md="12"
          xl="6"
        >
          <div>
            <label for="workplace">Período</label>
            <b-form-input
              id="workplace"
              :value="`${formatDateTimeDDMMYYYY(invoice.start_date)} - ${formatDateTimeDDMMYYYY(invoice.start_date)}`"
              name="workplace"
              disabled
            />
          </div>
          <div>
            <label for="deadline-date">Vencimento</label>
            <b-form-datepicker
              id="deadline-date"
              v-model="invoice.deadline"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="pt-BR"
              label-help=""
              label-no-date-selected="Selecione uma data"
            />
          </div>
        </b-col>
      </b-row>
    </b-form>
    <h5>Plantões</h5>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="text-right titles">
        <div class="d-flex justify-content-end">
          <div class="d-flex flex-column text-left">
            <span class="text-muted total">Total</span>
            <span class="text-muted text-">Instituição</span>
          </div>
          <span class="amount align-self-end"> {{ formatCurrencyInt(totalAllDuties(invoice)) }}</span>
        </div>
      </div>
      <app-collapse
        accordion
        type="border"
        class="mb-1"
      >
        <app-collapse-item
          v-for="workplace in invoice.workplaces"
          :key="workplace.workplace_id"
          :title="workplace.workplace_name"
        >
          <template v-slot:header>
            <div
              class="d-flex justify-content-between w-100"
              :class="{'text-removed': false}"
            >
              <div class="d-flex">
                <b-badge
                  pill
                  variant="light-primary"
                  title="Quantidade de plantões"
                >
                  {{ workplace.items.length }}
                </b-badge>
                <div class="ml-1">
                  <h6>Unidade</h6>
                  <h4>{{ workplace.workplace_name }}</h4>
                </div>
              </div>
              <div class="text-right">
                <div>
                  <span class="text-muted">Total</span>
                  <span class="collapse-title"> {{ formatCurrencyInt(totalDuties(workplace)) }}</span>
                </div>
              </div>
            </div>
          </template>
          <b-table
            ref="refInstitutionListTable"
            :items="() => workplace.items"
            responsive
            :fields="tableColumns"
            primary-key="id"
            show-empty
            empty-text="Nenhum registro correspondente encontrado"
            class="position-relative"
            small
          >
            <template #cell(physician_name)="data">
              <div :class="{'text-removed': data.item.removed}">
                {{ data.item.physician_name }}
              </div>
            </template>
            <template #cell(physician_cpf)="data">
              <div :class="{'text-removed': data.item.removed}">
                {{ data.item.physician_cpf }}
              </div>
            </template>
            <template
              #cell(anteciped_at)="data"
            >
              <div :class="{'text-removed': data.item.removed}">
                {{ formatDate(new Date(data.item.anteciped_at), 'dd/MM/yy HH:mm') }}
              </div>
            </template>
            <template #cell(start_date)="data">
              <div :class="{'text-removed': data.item.removed}">
                {{ formatDate(new Date(data.item.start_date), 'dd/MM/yy HH:mm') }} -
                {{ formatDate(new Date(data.item.end_date), 'dd/MM/yy HH:mm') }}
              </div>
            </template>
            <template #cell(amount_paid)="{ item }">
              <div :class="{'text-removed': item.removed}">
                {{ formatCurrencyInt(item.amount_paid) }}
              </div>
              <div>
                <b-badge
                  pill
                  class=""
                  :variant="item.source === 'physician_duty' ? 'light-success' : 'light-danger'"
                >
                  {{ item.source === 'physician_duty' ? 'Plantão' : 'Serviço' }}
                </b-badge>
              </div>
            </template>
            <template #cell(company)="{ item }">
              <b-badge
                pill
                :variant="item.person_type === 'PJ' ? 'light-danger' : 'light-success'"
              >
                {{ item.person_type || 'PF' }}
              </b-badge>
              <div v-if="item.physician_company_cnpj">
                <strong>{{ item.physician_company_name }}</strong>
                <div>{{ formatCnpj(item.physician_company_cnpj) }}</div>
              </div>
              <div v-else>
                -
              </div>
            </template>
            <template #cell(actions)="data">
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="flat-danger"
                class="btn-remove text-danger"
                @click="removeDuty(data.item)"
              >
                <feather-icon
                  icon="TrashIcon"
                  size="14"
                />
              </b-button>
            </template>
          </b-table>
        </app-collapse-item>
      </app-collapse>
    </b-card>

    <b-button
      variant="outline-primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :to="{name: 'invoice'}"
    >
      Voltar
    </b-button>
    <b-button
      variant="secondary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="onSubmit"
    >
      Salvar
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard, BFormGroup, BFormTextarea, BButton, BForm, BFormDatepicker, BCol, BRow, BTable, BFormInput, BBadge,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import contractStoreModule from '@/views/financial/contractStoreModule'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  formatDateTime, maskCnpj, maskHiddenCpf, useRouter, pad,
  formatCurrency, formatCurrencyInt, formatDate, formatDateTimeDDMMYYYY, toastValidationErrors, formatCnpj,
} from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBadge,
    AppCollapse,
    AppCollapseItem,
    BFormInput,
    BTable,
    BRow,
    BCol,
    BFormDatepicker,
    BForm,
    BCard,
    BButton,
    BFormGroup,
    BFormTextarea,
  },
  methods: { formatCnpj },
  setup() {
    const invoice = ref({})

    const APP_STORE_MODULE_NAME = 'app-contract'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, contractStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const tableColumns = [
      {
        key: 'physician_name',
        label: 'Médico',
      },
      {
        key: 'physician_cpf',
        label: 'CPF',
      },
      {
        key: 'company',
        label: 'Empresa',
      },
      {
        key: 'anteciped_at',
        label: 'Antecipado',
      },
      {
        key: 'start_date',
        label: 'Data',
      },
      {
        key: 'amount_paid',
        label: 'Valor',
      },
      {
        key: 'actions',
        label: 'Ações',
      },
    ]

    const fetchInvoice = () => {
      store.dispatch('app-contract/fetchInvoice', { id: router.currentRoute.params.id })
        .then(response => {
          const workplaces = response.data.data.workplaces.map(workplace => {
            workplace.items.forEach(duty => {
              // eslint-disable-next-line no-param-reassign
              duty.removed = false
            })
            return workplace
          })
          invoice.value = {
            ...response.data.data,
            workplaces,
          }
        })
        .catch(error => {
          if (error.response.status === 404) {
            invoice.value = undefined
          }
        })
    }
    fetchInvoice()

    function leftContracts() {
      const duties = []
      invoice.value.workplaces.forEach(workplace => {
        duties.push(...workplace.items)
      })
      return duties
        .filter(d => !d.removed)
    }

    function removeDuty(duty) {
      // eslint-disable-next-line no-param-reassign
      duty.removed = !duty.removed
    }

    function onSubmit() {
      const {
        id,
        deadline,
        note,
        start_date,
        end_date,
      } = invoice.value
      const contracts = leftContracts().map(duty => duty.contract_id)
      if (!contracts.length) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Erro',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
            text: 'Não é possível remover todos plantões!',
          },
        })
        return
      }
      const payload = {
        id,
        deadline,
        start_date,
        end_date,
        note,
        contracts,
      }
      store.dispatch('app-contract/updateInvoice', payload)
        .then(() => {
          fetchInvoice()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Editado',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'Editado com sucesso!',
            },
          })
        }).catch(toastValidationErrors)
    }

    const totalDuties = report => report.items
      .filter(d => !d.removed)
      .map(d => d.amount_paid)
      .reduce((partialSum, a) => partialSum + a, 0)
    const totalAllDuties = invoic => invoic.workplaces.reduce((partialSum, workplace) => partialSum + totalDuties(workplace), 0)

    return {
      totalAllDuties,
      totalDuties,
      pad,
      removeDuty,
      invoice,
      onSubmit,
      formatDateTime,
      maskCnpj,
      maskHiddenCpf,
      useRouter,
      formatCurrency,
      formatCurrencyInt,
      formatDate,
      formatDateTimeDDMMYYYY,
      tableColumns,
    }
  },
}
</script>

<style lang="scss">
  .text-removed {
    text-decoration: line-through;
    color: #9fa2a7;
  }

  .advanced-invoice {
    .panel-physician {
      background-color: rgb(248, 248, 248);
    }

    label {
      margin-top: 0.2857rem!important;
    }

    .collapse-border .card .card-header {
      padding-top: .5rem;
      padding-bottom: .5rem;
      h4 {
        margin-bottom: 0;
      }
      div.d-flex {
        align-items: center;
      }
    }

    .duty-dates {
      align-items: center;
      h1 {
        color: #898993;
        font-size: 14px;
        margin-bottom: 0;
        margin-right: 10px;
      }
    }

    .physician-label {
      font-size: 12px;
    }

    .btn-remove {
      padding: 8px 14px;
    }
    .text-removed {
      text-decoration: line-through;
      color: #9fa2a7;
      h4, h6 {
        color: #9fa2a7;
      }
    }
    .btn-success {
      padding: 0.615rem 0.636rem!important;
    }
    h6 {
      color: #6e6b7b;
      font-size: 0.85rem;
      font-weight: 400;
      margin-bottom: 0;
    }

    .btn-unit {
      margin-bottom: 0.5rem!important;
    }

    .titles {
      font-weight: bold;
      .text-muted {
        color: #575756!important;
      }
      .total {
        font-weight: 400;
        font-size: 0.75rem;
      }
      .amount {
        margin-left: 3px;
      }
    }
  }
</style>
